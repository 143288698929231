.loading-overlay {
  background: map-get($colors, black);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
}

.loading-window {
  background-color: map-get($colors, off-white);
  width: 26.25rem;
  padding: 0.9rem 1.6rem;
}

.loading-content {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.loading-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.loading-bar {
  width: 100%;
  height: 1.5rem;
  border: map-get($border-sizes, light) solid map-get($colors, black);
  background-color: map-get($colors, white);
  position: relative;
  border-radius: 3.125rem;
  overflow: hidden;
}

.loading-progress {
  height: 100%;
  background-color: map-get($colors, black);
}

.loading-pulsing {
  animation: pulse 0.8s linear infinite;
}

@media screen and (max-width: $mobile-breakpoint) {
  .loading-window {
    width: 18rem;
  }

  .loading-welcome {
    display: none;
  }
}
