h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
