*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:focus {
  color: inherit;
}

a:active {
  color: inherit;
  text-decoration: none;
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.relative {
  display: flex;
  flex-direction: column;
  position: relative;
}

.full-height {
  height: 100vh;
  // overflow-y: auto;
}

body {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  background-attachment: fixed;
}

.centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
