:root,
body {
}

.time-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, white);
  leading-trim: both;
  text-edge: cap;
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.04rem;
}

.nav-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, black);
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}

.availability-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, black);
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}

.cta-call-type {
  @include inconsolata-black;
  color: map-get($colors, black);
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}

.cta-email-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, black);
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
}

.hero-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, black);
  text-align: center;
  font-family: Inconsolata;
  font-size: 2.5rem;
  line-height: 100%; /* 2.5rem */
  letter-spacing: 0.025rem;
  text-transform: uppercase;
}

.hero-asterisk-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, white);
  text-align: center;
  -webkit-text-stroke-width: 0.03125rem;
  -webkit-text-stroke-color: map-get($colors, black);
  font-size: 2.5rem;
  line-height: 100%; /* 2.5rem */
  letter-spacing: 0.025rem;
  text-transform: uppercase;
}

.site-status-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, black);
  font-size: 0.85rem;
  line-height: 1.02rem;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
}

.loading-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, black);
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}

.menu-link-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, black);
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}

.filter-title-type {
  @include inconsolata-semi-bold;
  color: map-get($colors, black);
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}

.filter-type {
  @include inconsolata-medium;
  color: map-get($colors, black);
  font-size: 0.9375rem;
  line-height: normal;
  letter-spacing: 0.0375rem;
  text-transform: uppercase;
}

.work-card-type {
  @include inconsolata-medium;
  color: map-get($colors, black);
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
}

.work-card-label-type {
  @include inconsolata-medium;
  color: map-get($colors, gray-type-dark);
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
}

.is-dark {
  .cta-call-type,
  .cta-email-type {
    color: map-get($colors, off-white2);
  }
}

.landing-footer.is-dark {
  .nav-type {
    color: map-get($colors, off-white2);
  }
}

.work-back-type {
  @include montserrat-black;
  color: #000;
  font-size: 1rem;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}

.more-work-header-type {
  @include montserrat-semi-bold;
  color: map-get($colors, off-white2);
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}

.work-detail-paragraph-type {
  @include inconsolata-regular;
  color: #e6e6e6;
  font-size: 1.25rem;
  line-height: 120%; /* 1.5rem */
}

.work-detail-title-type {
  @include montserrat-bold;
  color: #000;
  font-size: 3.375rem;
  line-height: 100%;
  text-transform: uppercase;
}

.work-detail-summary-type {
  @include inconsolata-regular;
  color: #000;
  font-size: 1.25rem;
  line-height: 120%;
}

.work-kind-tags-type,
.work-kind-tools-type {
  @include inconsolata-medium;
  color: #000;
  font-size: 0.9375rem;
  letter-spacing: 0.0375rem;
}

.work-tag-type {
  @include inconsolata-medium;
  color: #000;
  font-size: 0.9375rem;
  letter-spacing: 0.0375rem;
  text-transform: uppercase;
}

@media screen and (max-width: $tablet-breakpoint) {
  .hero-type {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .work-detail-title-type {
    font-size: 2.3rem;
  }

  .filter-type {
    font-size: 0.8rem;
  }

  .work-detail-summary-type {
    font-size: 1.1rem;
  }

  .work-detail-title-type {
    font-size: 1.9rem;
  }
}

@media screen and (min-width: $large-desktop-breakpoint) {
  .nav-type,
  .availability-type,
  .time-type,
  .cta-call-type,
  .cta-email-type,
  .loading-type {
    font-size: 1.2rem;
  }
  .hero-type {
    font-size: 2.8rem;
  }

  .site-status-type {
    font-size: 1rem;
  }
}
