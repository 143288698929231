.time {
  display: flex;
  padding: 0.375rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.125rem;
  background: map-get($colors, black);
  word-spacing: -0.1875rem;
}

.time .ellipse {
  width: 0.5625rem;
  height: 0.5625rem;
  border-radius: 50%;
  background-color: map-get($colors, white);
  animation: pulse 1.1s linear infinite;
}

@keyframes pulse {
  0%,
  49.99% {
    visibility: visible;
  }
  50%,
  100% {
    visibility: hidden;
  }
}
