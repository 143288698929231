.media-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1.25rem;
  width: 100%;
  box-sizing: border-box;
  max-width: 1800px;
  margin: 0 auto;
}

.media-item {
  display: flex;
  align-items: center;
  grid-column: span 1;
}

.media-item.col-span-1 {
  grid-column: span 1;
}

.media-item.col-span-2 {
  grid-column: span 2;
}

.media-item.col-span-3 {
  grid-column: span 3;
}

.media-item.col-span-4 {
  grid-column: span 4;
}

.media-item.col-span-5 {
  grid-column: span 5;
}

.media-item.col-span-6 {
  grid-column: span 6;
}

.media-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

iframe {
  min-height: 31.25rem;
}

@media screen and (max-width: $mobile-breakpoint) {
  .media-grid {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }

  .media-item {
    grid-column: span 1;
    height: auto;
  }
}
