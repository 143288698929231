.scroll-to-top {
  display: inline-flex;
  align-items: center;
  gap: 1.5rem;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  position: sticky;
  z-index: 8;
  right: 0;
  bottom: 0;
  //   padding-right: 1.875rem;
  padding-bottom: 1.875rem;
  opacity: 0;
  transform: translateY(450px);
  transition: 1s ease-out;
  width: 235px;
  align-self: end;
}

.scroll-to-top.scroll {
  position: absolute;
}

.scroll-to-top.visible {
  opacity: 1;
  transform: translateY(0);
}

.button-to-top {
  display: flex;
  padding: 0.5rem 1.5rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 3.125rem;
  border: map-get($border-sizes, light) solid map-get($colors, black);
  background: map-get($colors, off-white);
  transition:
    400ms background-color,
    400ms color;
}

.scroll-to-top:hover .button-to-top {
  background-color: map-get($colors, black);
  color: map-get($colors, white);
}

.scroll-to-top:hover .button-to-top.is-dark {
  background-color: map-get($colors, hover-gray);
}

.to-top-arrow {
  width: 1.4375rem;
  height: 0.8125rem;
}

@media (max-width: $mobile-breakpoint) {
  .scroll-to-top {
    align-self: center;
  }
}
