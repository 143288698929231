.work-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 8rem 2.5rem;
  position: relative;
  max-width: 1880px;
  margin: 0 auto;
}

.filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem 1.25rem;
  gap: 0.6875rem;
}

.filters {
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  flex-wrap: wrap;
}

@media (max-width: $tablet-breakpoint) {
  .work-container {
    padding: 8rem 2rem;
  }
}

@media (max-width: $mobile-breakpoint) {
  .work-container {
    padding: 8rem 1.25rem;
  }

  .filter-container {
    padding: 0 0.25rem;
  }
}
