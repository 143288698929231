// CSS Properties
:root {
}

// SCSS Variables
$colors: (
  white: #fff,
  black: #000,
  gray: #ccc,
  gray-type: #808080,
  gray-type-dark: #909090,
  hover-gray: #a2a2a2,
  off-white: #faf9f7,
  off-white2: #e6e6e6,
);

$border-sizes: (
  light: 0.0625rem,
);

// Animations
$mobile-menu-transition: 0.3s;

// Define media breakpoints
$mobile-breakpoint: 640px;
$tablet-breakpoint: 992px;
$desktop-breakpoint: 1040px;
$large-desktop-breakpoint: 1500px;
$extra-large-breakpoint: 1800px;
