.hero {
  align-self: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: 450px;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('../../../public/pattern.svg'),
    url('../../../public/gradient-min.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.hero-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.hero-animation {
  width: 510px;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.hero-header {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  align-self: stretch;
  white-space: nowrap;
}

.header-container {
  width: 735px;
  overflow: hidden;
  position: relative;
  display: none;
}

@media screen and (max-width: $large-desktop-breakpoint) {
}

@media screen and (max-width: $tablet-breakpoint) {
  .hero-animation {
    width: 430px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .hero-animation {
    width: 355px;
  }
}

@media screen and (max-width: 735px) {
  .header-container {
    display: block;
  }

  .hero-header.non-marquee {
    display: none;
  }

  .header-container .hero-header {
    gap: 0.675rem;
    margin-left: 10px;
  }
}
