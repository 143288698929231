.cta {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cta div {
  white-space: nowrap;
}

.cta-email {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
}

.cta-arrow {
  width: 0.6rem;
  height: 0.6rem;
  overflow: hidden;
}

@media screen and (max-width: $mobile-breakpoint) {
  .cta-email {
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
  }

  .cta-arrow {
    position: relative;
    bottom: -3px;
  }
}

@media screen and (max-width: 370px) {
  .cta > div:first-child {
    display: none;
  }
}
