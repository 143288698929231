.filter {
  display: flex;
  padding: 0.25rem 1rem;
  align-items: center;
  border-radius: 3.125rem;
  border: 1px solid map-get($colors, black);
  cursor: pointer;
  white-space: nowrap;
  transition:
    background-color 0.4s ease-out,
    color 0.4s ease-out;

  &.selected {
    color: map-get($colors, white);
    background: map-get($colors, black);
  }
}

// .filter:hover {
//   background-color: map-get($colors, hover-gray);
// }

.filter-close {
  width: 0;
  height: 0;
  overflow: hidden;
  transition:
    width 0.4s cubic-bezier(0.25, 0.1, 0.25, 1),
    height 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.filter.selected .filter-close {
  margin-left: 0.375rem;
  width: 0.59063rem;
  height: 0.5625rem;
}

#close_path {
  fill: map-get($colors, white);
}
