.landing-footer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  &.is-dark {
    background-color: map-get($colors, black);
  }
}

.landing-footer-top {
  display: flex;
  padding: 1rem 2.5rem;
  justify-content: right;
  align-items: center;
  max-width: 1880px;
  width: 100%;
}

.landing-footer-bottom {
  display: flex;
  padding: 1rem 2.5rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  position: relative;
}

.landing-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  max-width: 1800px;
}

.graphic-elements {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  position: absolute;
}

.barcode {
  width: 6.25rem;
}

.target {
  width: 1.875rem;
}

.globe {
  width: 2.125rem;
}

@media screen and (max-width: $large-desktop-breakpoint) {
  .graphic-elements {
    gap: 0.35rem;
  }

  .barcode {
    width: 5rem;
  }

  .target {
    width: 1.3rem;
  }

  .globe {
    width: 1.5rem;
  }

  .landing-footer-content .nav-links {
    gap: 0.84rem;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .landing-footer-top {
    padding: 1rem 1.25rem;
  }

  .landing-footer-bottom {
    display: flex;
    padding: 1rem 1.25rem;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
  }

  .landing-footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.3rem;
  }

  .graphic-elements {
    position: static;
    top: initial;
    left: initial;
    transform: none;
  }

  .cta {
    order: 1;
  }

  .landing-footer-content nav {
    order: 2;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .landing-footer-top {
    justify-content: center;
  }

  .landing-footer-bottom {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex-shrink: 0;
    align-self: stretch;
  }

  .landing-footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
  }

  .landing-footer-content .nav-links {
    gap: 1.5rem;
    align-self: stretch;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 400px) {
  .landing-footer-content .nav-links li:last-child {
    display: none;
  }
}
