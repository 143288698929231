.work-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.25rem;
  flex-shrink: 0;
  min-height: 300px;
  cursor: pointer;
}

.work-card-image,
.work-card-video {
  align-self: stretch;
  clip-path: inset(0 100% 0 0);
  max-width: 100%;
}

.work-card-image.loaded,
.work-card-video.loaded {
  animation: 0.7s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s forwards inset;
}

.work-card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.9rem;
  align-self: stretch;
}

.work-card-labels {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  align-self: stretch;
}

.work-card-label {
  display: flex;
  padding: 0.3125rem 0.4375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 5.625rem;
  border: 1px solid map-get($colors, gray-type-dark);
}

.underline {
  color: map-get($colors, black);
  text-decoration: none;
  position: relative;
}

.underline.is-dark {
  color: map-get($colors, off-white2);
}

.underline:after {
  content: '';
  position: absolute;
  bottom: -0.2rem;
  left: 0;
  width: 0%;
  border-bottom: 0.063rem solid map-get($colors, black);
  transition: 0.5s cubic-bezier(0, 0, 0.25, 1);
}

.underline.is-dark:after {
  border-bottom-color: map-get($colors, off-white2);
}

.work-card:hover .underline:after {
  width: 100%;
}

@keyframes inset {
  100% {
    clip-path: inset(0 0 0 0);
  }
}

@media (max-width: $mobile-breakpoint) {
  .work-card {
    gap: 1.3rem;
  }
}
