.work-back {
  display: flex;
  padding: 5rem 2.5rem 0.625rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 0.625rem;
  align-self: stretch;
}

.work-back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.work-back-icon {
  width: 0.9375rem;
  height: 0.6975rem;
  fill: map-get(
    $colors,
    black
  ); /* Ensure this function is defined in your SCSS setup */
  position: relative;
  top: -0.1875rem;
}

.sticky {
  position: sticky;
  top: 4.524rem;
  z-index: 1;
  max-width: 1880px;
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: $tablet-breakpoint) {
  .work-back {
    padding: 5rem 1.25rem 0.625rem;
  }

  .sticky {
    position: static;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .work-back {
    padding: 5rem 1rem 0.625rem;
  }
}

@media screen and (min-width: $large-desktop-breakpoint) {
  /* No rules specified; remove or add as needed */
}
