.work-detail-info {
  display: flex;
  padding: 7.5rem 2.5rem;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.work-detail-header {
  display: flex;
  width: 48.625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}

.work-detail-title {
  align-self: stretch;
}

.work-detail-summary {
  display: flex;
  padding: 0 10px;
  align-items: flex-start;
  p {
    max-width: 47rem;
  }
}

.work-kind {
  display: flex;
  width: 12.25rem;
  padding-left: 0.625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  .filter {
    cursor: auto;
  }
}

.work-kind-tags,
.work-kind-tools {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.work-tags,
.work-tools {
  display: flex;
  padding: 0.25rem 0;
  align-items: flex-start;
  gap: 0.375rem;
  flex-wrap: wrap;
}

.work-tag {
  display: flex;
  width: 9.5625rem;
  padding: 0.25rem 1rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 3.125rem;
  border: 1px solid #000;
}

@media screen and (max-width: $tablet-breakpoint) {
  .work-detail-info {
    padding: 7.5rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .work-detail-header {
    width: auto;
    gap: 1.3rem;
  }

  .work-kind {
    flex-direction: row;
    padding: 0 0.625rem;
    gap: 1.25rem;
    width: auto;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .work-detail-info {
    padding: 7.5rem 1rem;
  }

  .work-detail-header {
    gap: 1rem;
  }
}
