.nav {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  align-self: stretch;
  z-index: 10;
  justify-content: center;
  align-items: center;
  border-bottom: map-get($border-sizes, light) solid map-get($colors, black);
}

.nav-content {
  display: flex;
  padding: 1rem 2.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  backdrop-filter: blur(20px);
  opacity: 0.99;
  max-width: $extra-large-breakpoint;
  flex: 1 0 0;
}

.is-dark {
  &.nav {
    border: none;
  }

  .nav-content {
    opacity: 1;
    backdrop-filter: none;
    justify-content: right;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .nav-content {
    padding: 1rem 1.25rem;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .nav-content {
    padding: 1rem;
  }

  .nav .nav-links {
    display: none;
  }
}

@media screen and (min-width: $extra-large-breakpoint) {
  .nav-content {
    padding: 1rem 0;
  }
}
